$(function(){

	if ($('#notification').length) {
		notificationWindow();
	}

	saveZeiterfassung();
	loadActualDay();

	$(document).on('click', '#zeiterfassung-add', function(e){
		addZeiterfassung($(this).closest('.section-content'));
		renderJs();
		return false;
	});

	$(document).on('click', '.time-controll button', function() {
		var $form = $(this).closest('form');
		var $section = $(this).closest('section');
		var mode = $(this).data('call');

		if (mode == 'start') {
			// wenn wir start clicken triggern wir die anderen timer mit pause
			$('button[data-call="pause"]').trigger('click');
			_timer = setInterval(function(){ timer($section) }, 1000);
			$section.find('.time-controll').children('.disabled').removeClass('disabled');
			$(this).addClass('disabled');
			$section.addClass('time-is-running');
			var seconds = $section.find('.zeiterfassung-seconds').val();
			$section.find('.zeiterfassung-starttime').val(Math.ceil(Date.now() / 1000) - seconds);
			$section.addClass('started');

			// falls wir eine task_id haben, und die daten bereits synchronisiert sind, wollen wir den status in zenkit anpassen
			var task_phase = $form.find('.zeiterfassung-task-phase').val();
			if (task_phase) {
				// Phase auf Doing setzten und speichern
				$form.find('.zeiterfassung-task-phase').val('Doing');

				var response = AppApi.post('task', $form.serialize(), "/" + $form.find('.zeiterfassung-task-id').val());

				if (response.success) {
					if (response.data) {
						var task = response.data;
						if (task.task_phase) {
							$form.find('.taskdetail-phase').children('span').text(task.task_phase);
							$form.find('.zeiterfassung-task-phase').val(task.task_phase);
						}
					}

				}
			}
		} else if (mode == 'pause') {
			window.clearInterval(_timer);
			$(this).addClass('disabled');
			$section.find('button[data-call="start"]').removeClass('disabled');
			$section.removeClass('time-is-running');
			$section.find('.zeiterfassung-starttime').val(0);

		} else if (mode == 'stop') {
			window.clearInterval(_timer);
			$section.removeClass('time-is-running').removeClass('started').addClass('stopped');
			$section.find('.zeiterfassung-starttime').val(0);
		}
		// temporaere daten speichern
		saveZeiterfassungTimer();
	})

	$(document).on('click', '.zeiterfassung-time', function(){
		var $inputTime = $(this);
		$inputTime.hide();
		var $zeiterfassungDirect = $inputTime.parent().children('.zeiterfassung-direct');
		var $zeiterfassungDirectLabel = $inputTime.parent().children('.zeiterfassung-direct-label');
		var $inputSeconds = $inputTime.parent().find('.zeiterfassung-seconds');
		var $zeiterfassungRezeit = $inputTime.parent().find('.zeiterfassung-rezeit');
		$zeiterfassungRezeit.hide();

		$zeiterfassungDirect.show().focus().val(Math.ceil($inputSeconds.val() / 60)).select();
		$zeiterfassungDirectLabel.show();
		$zeiterfassungDirect.on('blur', function(){
			let $this = $(this);
			let dayminutes = parseInt(_configuration.dayhours) * 60;
			if (parseInt($this.val()) > dayminutes) {
				dlySwal.fire({
					title: 'Fehler!',
					text: 'Deine Eingabe ist größer als Deine Arbeitszeit. Die Zeit wird automatisch herabgesetzt',
					confirmButtonText: 'Ok'
				});
			}
			$this.hide();
			$zeiterfassungDirectLabel.hide();
			var seconds = $this.val() * 60
			$inputSeconds.val(seconds);
			$inputTime.show().html(secondsToHHMMSS(seconds));
			$zeiterfassungRezeit.show();
			var $section = $this.closest('section');
			// wenn dieser timer gerade laeuft, dann wollen wir die starttime neu setzen
			if ($section.hasClass('time-is-running')) {
				var starttime = Math.ceil(Date.now() / 1000) - seconds;
				$section.find('.zeiterfassung-starttime').val(starttime);
			}
			saveZeiterfassungTimer();
			timer($section,true);
		});
	});

	$(document).on('click', '.js-save-and-change-phase', function(){
		var $this = $(this);
		var $form = $this.closest('form');
		var phase = $this.data('phase');
		var r = confirm("Willst du den Eintrag wirklich speichern und zusätzlich die Phase ändern?");
		if (r == true) {
			$form.find('.zeiterfassung-task-phase').val(phase);
			$form.find('.taskdetail-phase').children('span').text(phase);
			// den button triggern und nicht submit, damit die validierung stattfindet
			$form.find('button[type="submit"]').trigger('click');
		}
		return false;
	});

	$(document).on('submit', '#section-zeiterfassung form', function(e){
		window.showLoading();
		// Pause Button Triggern
		var $this = jQuery(this);
		var $title = $this.find('.zeiterfassung-title');
		var $description = $this.find('.zeiterfassung-text');

		$this.find('button[data-call="pause"]').trigger('click');

		let timestamp = new Date();
		if (window._date) {
			timestamp = window._date;
		}
		timestamp = Math.ceil(timestamp.getTime() / 1000);

		$this.find('.zeiterfassung-timestamp').val(timestamp);

		var response = AppApi.put('task', $this.serialize());
		if (response.success) {
			var $section = $this.closest('section');

			$section.removeClass('started');

			$section.find('.panel-body').html(loadTemplate('saved', {
				type: 'success',
				message: 'Stundenzettel wurde wie folgt angelegt:',
				title: $title.val(),
				description: $description.val(),
				id: response.infologId,
				swornproId: response.swornproId,
				posten: response.posten ? true : false
			}));

			$section.closest('.zeiterfassung').addClass('zeiterfassung-saved');

			if (_configuration['remove-saved-entries']) {
				// gespeicherte automatisch entfernen
				// wir speichern unsere Variable zwischen, nich das sie irgendwo ueberschrieben wird.
				var $removeSavedEntries = $section;
				window.setTimeout(function(){
					$removeSavedEntries.fadeOut();
				},10000);
			}
			$section.fadeOut(function(){
				$(this).closest('.zeiterfassung').remove();
			});
			// Aktuelle Tagesstunden aktualisieren
			loadActualDay();
			loadRequestCounter();
			addBalken();
			renderJs();
			calculateBalken();
		} else if (response.error) {
			$this.closest('.panel').find('.panel-heading').trigger('click');
			dlySwal.fire({
				title: 'Fehler!',
				text: response.error,
				confirmButtonText: 'Ok'
			});
		}

		// temporaere daten speichern
		saveZeiterfassungTimer();
		window.hideLoading();
		return false;
	});

	$(document).on('submit', '#balken form', function(e){
		let project = $(this).find('.selectpicker-projects').val();
		let phase = $(this).find('.selectpicker-projects-phase').val() ?? false;
		let leistungsart = $(this).find('.selectpicker-leistungsart').val() ?? false;
		let text = $(this).find('.zeiterfassung-text').val() ?? '';
		if (project) {
			let seconds = 0;
			if (window._savedStundenzettel) {
				for (let s in window._savedStundenzettel) {
					seconds += parseInt(window._savedStundenzettel[s].seconds);
				}
			}
			$('.zeiterfassung-seconds').each(function(){
				seconds += parseInt($(this).val());
			});
			let dayhours = '' + _configuration.dayhours;
			let rest = Math.ceil(((dayhours * 60 * 60) - seconds) / 100 * 90);
			if (rest < 900) {
				rest = 900;
			}
			let prefill = {
				'project': project,
				'phase': phase,
				'leistungsart': leistungsart,
				'seconds': rest,
				'title': '',
				'text': text,
				'abrechnungstyp' : false,
				'stammswornproid' : false,
				'swornproid' : false,
				'phaseswornproid' : false,
				'show_time' : '' + !!window._configuration.show_time
			};
			addZeiterfassung($('#section-zeiterfassung'), prefill);
			renderJs();
		}

		saveZeiterfassungTimer();
		calculateBalken();

		$('#add-project').modal('hide');
		// Select und Textarea in Modal zurücksetzen
		$('#add-project .selectpicker-projects').val('').trigger('change');
		$('#add-project .selectpicker-projects-phase').val('').trigger('change');
		$('#add-project .selectpicker-leistungsart').val('').trigger('change');
		$('#add-project .zeiterfassung-text').val('');
		return false;
	});
	$(document).on('click', '#button-add-project', function(){
		$('#add-project').modal('show');
		$('#add-project').off('shown.bs.modal').on('shown.bs.modal', function (e) {
			$('#add-project .selectpicker-projects').select2('open');
		})
		return false;
	});

	$(document).on('click', '.zeiterfassung-close', function(e){
		e.stopPropagation();
		var r = confirm("Willst du den Eintrag wirklich aus der Liste entfernen?\nNicht gespeicherte Daten gehen dabei verloren!");
		if (r == true) {
			$(this).closest('.zeiterfassung').fadeOut(function(){
				$(this).remove()
				saveZeiterfassungTimer();
			});
		} else {

		}
		return false;
	});

	$(document).on('click', '.addressdata-close', function(e){
		e.stopPropagation();
		var r = confirm("Willst du den Eintrag wirklich aus der Liste entfernen?");
		if (r == true) {
			$(this).closest('.addressdata').fadeOut(function(){
				$(this).remove();
				if ($('.addressdata-items').children().length < 1) {
					$('.addressdata-close-all').hide();
				}
			});
		}
		return false;
	});

	$(document).on('click', '.addressdata-close-all', function(e){
		e.stopPropagation();
		var r = confirm("Willst du wirklich alle Einträge aus der Liste entfernen?");
		if (r == true) {
			$('.addressdata-items').children().fadeOut(function(){
				$('.addressdata-items').html('');
			});
			$('.addressdata-close-all').hide();
		}
		return false;
	});	

	$(document).on('click', '.addressdata-starttimesheet', function(e){
		e.stopPropagation();
		var company = $(this).attr('data-companyname');
		addTrackingByCompanyname(company);
		window.location.href = '#zeiterfassung';
		return false;
	});

	$(document).on('change', '.selectpicker.selectpicker-address', function(e){
		var $this = $(this);
		var $selected = $(this).find(":selected");
		if (AddressbookData[$selected.val()]) {
			loadAddress(false, AddressbookData[$selected.val()]);
		}
	});

	$(document).on('change', '#dayhours', function(){
		let $this = $(this);
		let hours = $this.val();
		hours = parseFloat(hours.replace(',','.'));
		if (!hours || hours <= 0) {
			dlySwal.fire({
				title: 'Fehler!',
				text: 'Unzulässige Zahl',
				confirmButtonText: 'Ok'
			});
			hours = 8;
			$this.val(hours);
		}
		window._configuration.dayhours = hours;
		store.set('configuration', _configuration);
		addBalken();
		renderJs();
		calculateBalken();
	});

	$(document).on('change', '.zeiterfassung-show-time', function(){
		let $this = $(this);
		if ($this.is(':checked')) {
			$this.closest('.zeiterfassung').addClass('show-time');
		} else {
			$this.closest('.zeiterfassung').removeClass('show-time');
		}
	});

	$(document).on('change', '#show-time-all', function(){
		let $this = $(this);
		if ($this.is(':checked')) {
			window._configuration.show_time = true;
			$('.zeiterfassung-show-time').prop('checked', true).trigger('change');
			$('.saved-stundenzettel-time').removeClass('hide');
		} else {
			window._configuration.show_time = false;
			$('.zeiterfassung-show-time').prop('checked', false).trigger('change');
			$('.saved-stundenzettel-time').addClass('hide');
		}
		store.set('configuration', _configuration);
	});

	$(document).on('change', '#date', function(){
		let $this = $(this);
		let value = $this.val();
		window._date = new Date(value);
		let timestamp = Math.ceil(window._date.getTime() / 1000);
		$('.zeiterfassung-timestamp').val(timestamp);
		// Bereits gespeicherte neu laden
		addBalken();
		recalculateColor();
		renderJs();
		calculateBalken();
		saveZeiterfassungTimer(1);

	});

	$(document).on('click', '#zeiterfassung-save-all', function(){
		dlySwal.fire({
			text: "Willst Du wirklich alle Stunden speichern?",
			showCancelButton: true,
			confirmButtonText: 'Ja',
			cancelButtonText: 'Nein'
		}).then((result) => {
			if (result.isConfirmed) {
				window.showLoading();
				window.setTimeout(function(){
					$('.zeiterfassung-save').each(function(){
						$(this).trigger('click');
					});
				},100);

			}
		})
	})

	// Alle 10 Minuten aktualisieren
	window.setInterval(function(){
		loadActualDay();
		loadRequestCounter();
	},600000);
});

if ('serviceWorker' in navigator) {
	window.addEventListener('load', function() {
		navigator.serviceWorker.register('/service-worker.js');
	});
}