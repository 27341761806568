$(function(){


	init()

	function init() {

		if (!AppUser.isLoggedIn()) {
			$('#main-content').html(loadTemplate('login'));
			login();
			setSectionTitle('SWORNpro');
		} else {
			$('.navbar-toggle').show();
			$('#mainnavigation').html(loadTemplate('mainnavigation', {
				user:{
					user: AppUser
				}
			}));
			loadRequestCounter();
			// default home
			setContent('zeiterfassung');

			$(window).off('hashchange').on('hashchange', function(e) { 
				var hash = window.location.hash;
				var hash = hash.replace(/#/,'');
				console.log(hash);
				setContent(hash);
			});
			window.location.hash = 'zeiterfassung';
		}
	}

	function setContent(section) {
		// Menu aktiv setzen
		$('.active').removeClass('active');
		$('a[href="#' + section + '"]').parent().addClass('active');


		$('.section-content').hide();
		if ($('#section-' + section).length) {
			var $section = $('#section-' + section);
			$section.show();
		} else {
			$('#main-content').append(loadTemplate('default.content', {user: AppUser, section:section}));

			var $section = $('#section-' + section);

			switch(section) {
				case 'zeiterfassung':
					addBalken();
			    	if (Object.keys(_stundenzettel).length) {
			    		// wenn wir temporaer gespeicherte daten haben wollen wir diese hier laden
			    		for (var i in _stundenzettel) {
							if (_configuration.show_time) {
								_stundenzettel[i].show_time = 'true';
							}
			    			addZeiterfassung($section, _stundenzettel[i]);
			    		}
			    	} else {
			    		//addZeiterfassung($section);
			    	}
			    	//$section.find('.main-content-footer').html('<div class="row pxs_row"><div class="col-sm-12"><button class="btn btn-default" id="zeiterfassung-add"><zeit class="fa fa-plus" aria-hidden="true"></i></button></div></div>')
			    	setSectionTitle('Zeiterfassung');
					calculateBalken();
					$section.find('.main-content-footer').html(loadTemplate('save-all', {user: AppUser}));
			        break;
			    case 'termine':
			        $section.find('.main-content-body').html(loadTemplate('termine', {user: AppUser}));
			        setSectionTitle('Termine');
			        break;
			    case 'adressbuch':
			        var addresses = loadAddressbookData();
			        $section.find('.main-content-body').html(loadTemplate('adressbuch', {user: AppUser, addresses: addresses}));
			        setSectionTitle('Anruferliste');
			        break;
			    case 'intnummern':
			        var users = loadIntUsers();
			        $section.find('.main-content-body').html(loadTemplate('intnummern', {user: AppUser, users: users.list}));
			        setSectionTitle('Interne Telefonnummern');
			        break;
			    case 'einstellungen':
			        $section.find('.main-content-body').html(loadTemplate('einstellungen', {user: AppUser, conf: _configuration}));
			        setSectionTitle('Einstellungen');
			        break;
			    default:
			    	setContent('zeiterfassung');
			    	//setSectionTitle('Home');
			        //$section.find('.main-content-body').html(loadTemplate('home', {user: AppUser}));
			}
			renderJs();
			$section.show();
		}
		switch(section) {
		    case 'zeiterfassung':
		    	setSectionTitle('Zeiterfassung');
		    	// configuration beruecksichtigen
		    	if (_configuration['show-abrechnungstyp']) {
		    		// $section.addClass('zeiterfassung-show-abrechnungstyp');
		    		$section.removeClass('zeiterfassung-show-abrechnungstyp');
		    	} else {
		    		$section.removeClass('zeiterfassung-show-abrechnungstyp');
		    	}
		        break;
		    case 'termine':
		         setSectionTitle('Termine');
		        break;
		    case 'adressbuch':
		         setSectionTitle('Anruferliste');
		        break;
		    case 'intnummern':
		         setSectionTitle('Interne Telefonnummern');
		        break;
		    case 'einstellungen':
		         setSectionTitle('Einstellungen');
		        break;
			case 'logout':
				logout();
				break;
			case 'clearApiCache':
				clearApiCache();
				break;
		    default:
		    	//setSectionTitle('Home');
		    	setContent('zeiterfassung');
		}

	}

});